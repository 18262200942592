.custom-message {
  max-width: 400px;
  display: flex;
  margin: 0 auto;
  justify-content: center;

  .ant-message-custom-content {
    gap: 12px;
  }
}

.ant-message-notice {
  .ant-message-notice-content {
    background-color: #1b1b1c !important;
    padding: 12px !important;
    font-size: 12px !important;
  }
}

.custom-message.error {
  color: #f24848;
}
.custom-message.success {
  color: #36b286;
}
.custom-message.info {
  color: #36b286;
}
.custom-message.warning {
  color: #f24848;
}
