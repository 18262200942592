@font-face {
  font-family: 'Onest';
  src: url('../font/Onest-Regular.woff2') format('woff2'),
    url('../font/Onest-Regular.woff') format('woff'),
    url('../font/Onest-Regular.ttf') format('truetype');
  font-weight: 1;
  font-style: normal;
}
@font-face {
  font-family: 'Onest';
  src: url('../font/Onest-Thin.woff2') format('woff2'),
    url('../font/Onest-Thin.woff') format('woff'),
    url('../font/Onest-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Onest';
  src: url('../font/Onest-ExtraLight.woff2') format('woff2'),
    url('../font/Onest-ExtraLight.woff') format('woff'),
    url('../font/Onest-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Onest';
  src: url('../font/Onest-Light.woff2') format('woff2'),
    url('../font/Onest-Light.woff') format('woff'),
    url('../font/Onest-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Onest';
  src: url('../font/Onest-Regular.woff2') format('woff2'),
    url('../font/Onest-Regular.woff') format('woff'),
    url('../font/Onest-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Onest';
  src: url('../font/Onest-Medium.woff2') format('woff2'),
    url('../font/Onest-Medium.woff') format('woff'),
    url('../font/Onest-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Onest';
  src: url('../font/Onest-SemiBold.woff2') format('woff2'),
    url('../font/Onest-SemiBold.woff') format('woff'),
    url('../font/Onest-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Onest';
  src: url('../font/Onest-Bold.woff2') format('woff2'),
    url('../font/Onest-Bold.woff') format('woff'),
    url('../font/Onest-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Onest';
  src: url('../font/Onest-ExtraBold.woff2') format('woff2'),
    url('../font/Onest-ExtraBold.woff') format('woff'),
    url('../font/Onest-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Onest';
  src: url('../font/Onest-Black.woff2') format('woff2'),
    url('../font/Onest-Black.woff') format('woff'),
    url('../font/Onest-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

* {
  font-family: 'Onest', sans-serif !important;
}

main::-webkit-scrollbar {
  display: none !important;
}

/* Скрываем scrollbar для IE, Edge и Firefox */
main {
  /* -ms-overflow-style: none !important; */ /* IE and Edge */
  /* scrollbar-width: none !important; */
}

::-webkit-scrollbar {
  /* display: none !important; */
}

h2 {
  font-size: 20px;
}

.highlight {
  color: #f24848 !important;
}
